<template>
  <div class="mt-6">
    <h2 class="text-uppercase mb-3">Login to apical</h2>
    <md-divider></md-divider>
    <div class="md-layout md-gutter mt-6">
      <form @submit.prevent="onLogin" class="md-layout-item md-size-60 md-xsmall-size-100">
        <InputFieldComponent
          label="Email"
          class="my-4"
          v-model.trim="$v.form.email.$model"
          :message="hasEmailError"
          />
        <InputFieldComponent
          type="password"
          label="Password"
          class="my-4"
          v-model.trim="$v.form.password.$model"
          :message="hasPasswordError"
          
        />
        <div
          class="flex justify-between flex-wrap justify-sm-center justify-xs-center"
        >
          <md-checkbox v-model="boolean" class="md-secondary">
            <span class="font-semibold text-base">Save this password</span>
          </md-checkbox>
          <div class="flex align-center">
            <router-link
              :to="{ name: 'recoverPassword' }"
              class="text-victoria text-base"
              >forgot your password?</router-link
            >
            <md-button type="submit" class="bg-victoria text-white rounded">Login</md-button>
          </div>
        </div>
      </form>
      <div class="md-layout-item md-size-40 md-xsmall-size-100 md-xsmall-hide">
        <img src="/images/login-banner.png" alt="" />
      </div>
    </div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { InputFieldComponent } from "@/components";
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import { mapActions, mapMutations } from "vuex";
import {addSubdomain, getDomain} from "@/utils"
export default {
  components: {
    InputFieldComponent,
  },
  data: () => ({
    message: null,
    boolean: false,
    hasPasswordServerError: false,
    hasEmaildServerError: false,
    error: false,
    form: {
      email: "",
      password: "",
      organization:"",
      //type:"SUPER_ADMIN"
    },
  }),
  computed: {
    
    hasEmailError() {
      
      if ((!this.$v.form.email.required && this.$v.form.email.$dirty || !this.$v.form.email.email && this.$v.form.email.$dirty) || this.hasEmaildServerError) {
        if(this.hasEmaildServerError) {
          return this.hasEmaildServerError
        }
        return !this.$v.form.email.required && this.$v.form.email.$dirty ? 'Field is required' : !this.$v.form.email.email && this.$v.form.email.$dirty ? 'Should be in email format' : null;
      }
      return null;
    },
    hasPasswordError() {
      if (!this.$v.form.password.required && this.$v.form.password.$dirty || this.hasPasswordServerError) {
        if(this.hasPasswordServerError) {
          return this.hasPasswordServerError
        }
        return "Field is required";
      }
      return null;
    },

  },
  methods: {
    ...mapActions({
      actLogin: "user/actLogin",
      actValidSubdomain : "user/actValidSubdomain",
      actGetUserInformation: "user/actGetUserInformation",
    }),
    ...mapMutations({
      setUser: "user/setUser",
      setShowSnackbar: "setShowSnackbar",
    }),
    onLogin() {
     
      this.$v.$touch();
      if(this.$v.$invalid) return;

      this.loading = true;
      this.message = null;
      // let subdomain = window.location.host.split('.')[0];
      // if(subdomain == 'www') subdomain = window.location.host.split('.')[1];
      // this.form.organizations = subdomain;
      this.actLogin(this.form)
      .then((response) => {
          
          this.setShowSnackbar(response.message);
          
          if(!response.user_type || !response.subdomain){
            localStorage.setItem('token', response.token);
            localStorage.setItem('token2', response.token2);
            localStorage.setItem('user_id', response.user_id);
            localStorage.setItem('user_type', response.user_type === 'SUPER_ADMIN' ? 'MASTER' : response.user_type);
            localStorage.setItem('organization_id', response.organization_id);
            window.location.replace('master');
            // this.$router.push({ name: 'master' });
          }else{
        
        // console.log(response);
        // console.log(response._token2);
        // console.log(addSubdomain(response.subdomain));

            window.location.replace(addSubdomain(response.subdomain)+'/ref'+"?auth_token2="+response.token2+"&auth_token="+response.token);
            // window.location.replace(addSubdomain(response.subdomain)+'/'+response.user_type.toLowerCase()+"?auth_token="+response.token+"auth_token2="+response._token2);
            // this.$router.push({ name: response.user_type.toLowerCase() });
          }
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        if(errors){
          if(errors.email) {
            this.hasEmaildServerError = errors.email[0];
          }
          if(errors.password) {
            this.hasPasswordServerError = errors.password[0];
          }
        }
        if(error.response.data.message){
          this.hasPasswordServerError = error.response.data.message;
          this.setShowSnackbar(error.response.data.message);
          return
        }
        this.setShowSnackbar('Wrong credentials!');
      });
    }
  },
  validations: {
    form: {
      email: {required, email},
      password: {
        required,
      },
    },
  },
  created(){
    
    let subdomain = window.location.host.split('.')[0];
    if(subdomain == 'www') subdomain = window.location.host.split('.')[1];
    this.form.organization = subdomain;
    if(window.location.host != subdomain && subdomain!='stage-ui'){
        this.actValidSubdomain(this.form).then((response) => {
          if(!response){
            var protocal = window.location.protocol;
            var url = (process.env.NODE_ENV === "development") ? process.env.VUE_APP_ORIGIN_URL_DEV: process.env.VUE_APP_ORIGIN_URL_PROD;
            if(!url) url = 'stage-ui.apical.io';
             url = protocal+'//'+getDomain(url);
            window.location.href = url;
          }
        })
    }
  }
};
</script>
